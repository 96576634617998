.decor {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url(../../aseets/img/decor-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    position: relative;
    overflow: hidden;
    margin-top: 100px;
}

.fullWidthVideo {
    width: 100vw;
    height: auto;
}

.decor h1 {
    width: 961px;
    text-align: center;
}

@media screen and (max-width: 1200px) {
    .decor {
        height: 30vh;
    }

    .decor h1 {
        width: 80%;
        text-align: center;
    }

}