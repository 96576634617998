:root {
  --black: #000000;
  --white: #ffffff;
}

html {
  scroll-behavior: smooth;
}


body {
  background-color: var(--black);
  font-size: 14px;
  font-family: "Ginka", sans-serif;
  color: var(--white);
  overflow-x: hidden;
}

h1 {
  font-size: 82px;
  font-weight: 400;
  line-height: 98.4px;
}

h2 {
  font-size: 82px;
  font-weight: 400;
  line-height: 98.4px;
}

img {
  display: block;
}

.logo-32 {
  width: 32px;
  height: 32px;
}

.logo-66 {
  width: 66px;
  height: 66px;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  /* Для Internet Explorer и Edge */
  scrollbar-width: none;
  /* Для Firefox */
}

@media screen and (max-width: 1200px) {
  h1 {
    font-size: 40px;
    line-height: 48px;
  }

  h2 {
    font-size: 40px;
    line-height: 48px;
  }
}