.about {
    /* background-image: url(../../aseets/img/about-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    width: 100%;
    height: calc(40vh);
    padding: 10% 0 15% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -91px;
}

.container {
    max-width: 1201px;
    width: 100%;
    min-height: 201px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.container .heading {
    min-width: 494px;
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.container .info {
    width: 360px;
    display: flex;
    flex-direction: column;
    gap: 34px;
}


@media screen and (max-width: 1200px) {
    .about {
        background-image: url(../../aseets/img/about-bg-m.png);
        background-size: 100%;
        height: fit-content;
        margin-top: 0;
        padding: 89px 0;
        background-position: top;

    }
    .container .heading {
        width: 85%;
        margin: auto;
        gap: 13px;
        max-width: 100%;
        min-width: auto;
    }
    .heading img {
        display: none;
    }

    .container {
        flex-direction: column;
        gap: 40px;
    }

    .container .info {
        flex-direction: row;
        width: 80%;
        margin: auto;
        justify-content: space-between;
    }

    .container .info p {
        width: 148px;
    }

    
}