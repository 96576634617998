.UI_exampleCard {
    width: 181px;
    min-height: 224px;
    border-radius: 5px;
    padding: 15px 0 25px 0;
    display: flex;
    flex-direction: column;
    gap: 35px;
    box-sizing: border-box;
    position: relative;
    /* background: linear-gradient(44.28deg, #000000 43.97%, #1E1E1E 99.11%); */
    background: transparent;
    transition: all 0.5s;
    /* overflow: hidden; */
    z-index: 2;
}

.UI_exampleCard::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    background: linear-gradient(55deg, #000000 39.63%, #1F1F1F 61.24%, #373737 80.99%, #505050 91.05%, #6A6A6A 101.46%);
    transition: opacity 0.5s ease;
    z-index: -1;
    opacity: 0;
}

.UI_exampleCard:hover::after {
    opacity: 1;
}

.UI_exampleCard::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    min-width: 181px;
    height: 100%;
    min-height: 14px;
    background-image: url(../../aseets/img/example-card-border.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
}

.UI_exampleCard .number {
    border: 1px solid #545454;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    text-align: center;
    line-height: 19px;
}

.UI_exampleCard .title {
    display: -webkit-box;     
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.UI_exampleCard img {
    object-fit: contain;
}

@media screen and (max-width: 1200px) {
    .UI_exampleCard {
        width: 150px;
        width: 44%;
        min-height: 200px;
    }

    .UI_exampleCard::before {
        min-width: 150px;
    }
}