.UI_caseCard_wrapper {
    width: 400px;
    position: relative;
}

.UI_caseCard_wrapper .number_wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}

.UI_caseCard_wrapper .number_wrapper .number {
    border: 1px solid #545454;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    text-align: center;
    line-height: 19px;
}

.UI_caseCard_wrapper .card {
    border: 2px solid #545454;
    border-radius: 5px;
    padding: 30px 15px 65px 15px;
    display: flex;
    flex-direction: column;
    gap: 38px;
    background-image: url(../../aseets/img/CaseCard/bg-5.svg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.UI_caseCard_wrapper .card .icons {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
}

.UI_caseCard_wrapper .card .icons .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.UI_caseCard_wrapper .card .icons .icon p {
    text-align: center;
}

.UI_caseCard_wrapper .card .icons .icon img {
    flex-shrink: 0;
    object-fit: cover;
    margin-bottom: 7px;
}

.UI_caseCard_wrapper .card .hover-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #00000066;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.6s ease;
    border: 2px solid #545454;
    border-radius: 5px;
    backdrop-filter: blur(4px);
}

.UI_caseCard_wrapper .card .hover-content ul {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 305px;
    padding-left: 15px;
}

.UI_caseCard_wrapper .card .hover-content ul li {
    position: relative;
}

.UI_caseCard_wrapper .card .hover-content:hover {
    opacity: 1;
}

.UI_caseCard_wrapper .card .hover-content ul li::before {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: -14px;
    top: 6px;
}


.ml-15 {
    margin-left: 15px;
}

.pr-30 {
    padding-right: 30px;
}

.m-h-48 {
    min-height: 48px;
}

.UI_caseCard_wrapper .desc {
    max-width: 170px;
}

@media screen and (max-width: 1200px) {
    .UI_caseCard_wrapper {
        width: 100%;
    }

    .UI_caseCard_wrapper .card .hover-content {
        opacity: 0;
    }

    .UI_caseCard_wrapper .card:hover .hover-content {
        opacity: 0;
    }

    .UI_caseCard_wrapper .card .hover-content.visible {
        opacity: 1;
    }
}
