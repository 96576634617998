.UI_infoBar {
  height: 23px;
  width: 154px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;
  color: #ffffff;
  background: rgba(53, 53, 53, 0.6);
  border-radius: 44px;
  backdrop-filter: blur(5.3px);
  text-transform: uppercase;
}
