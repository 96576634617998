.UI_button {
    min-width: fit-content;
    padding: 0 10px;
    box-sizing: border-box;
    cursor: pointer;
    width: 117px;
    height: 28px;
    background: transparent;
    border-radius: 20px;
    border: 1px solid #363636;
    /* backdrop-filter: blur(4px); */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
}

.UI_button.big {
    height: 41px;
    border-radius: 71px;
}

.UI_button:hover {
    background: rgba(255, 255, 255, 0.1);
}

.UI_button:active {
    background: rgba(255, 255, 255, 0.2);
}