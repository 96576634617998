.about {
    background-image: url(../../aseets/img/what-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    width: 100%;
    height: auto;
    min-height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-right: 100px;
    padding-bottom: 50px;
    aspect-ratio: 1440 / 850;
    margin-bottom: -100px;
    margin-top: 50px;
}

.container {
    max-width: 1201px;
    width: 100%;
    min-height: 201px;
    display: flex;
    justify-content: space-between;
}

.container .heading {
    width: 505px;
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.container .info {
    width: 420px;
    display: flex;
    flex-direction: column;
    gap: 34px;
    padding-top: 70px;
}

.container .info p.big {
    width: 463px;
}


@media screen and (max-width: 1200px) {
    .about {
        width: 100%;
        margin: auto;
        min-height: auto;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 80px;
        padding: 0 10% 86px 10%;
        background-position: initial;
    }

    .container {
        flex-direction: column;
    }

    .container .heading {
        width: 210px;
        display: flex;
        flex-direction: column;
        gap: 13px;
    }

    .container .info {
        width: 345px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-top: 30px;
    }

    .container .info p.big {
        width: 345px;
    }
}