.UI_caseInfo {
    border: 2px solid #666666;
    border-radius: 17px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 17px 100px 50px 100px;
    box-sizing: border-box;
    overflow-x: hidden;
}

.UI_caseInfo .number-wrapper {
    display: flex;
    align-items: center;
    gap: 11px;
    margin-left: -80px;
}

.UI_caseInfo .number-wrapper .number {
    border: 1px solid #545454;
    width: 33px;
    height: 33px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    box-sizing: border-box;
    padding-bottom: 1px;
}

.UI_caseInfo .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.UI_caseInfo .container .info {
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 460px;
    flex-shrink: 0;
    padding-right: 30px;
}

.UI_caseInfo .container .info .f-20 {
    width: 420px;
}

.UI_caseInfo .left-container {
    width: 560px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.UI_caseInfo .left-container .text {
    width: 360px;
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.UI_caseInfo .left-container .text .challenge,
.UI_caseInfo .left-container .text .solution {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.UI_caseInfo .left-container .text ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 20px;
}

.UI_caseInfo .left-container .text ul li {
    position: relative;
}

.UI_caseInfo .left-container .text ul li::before {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: -14px;
    top: 6px;
}

.UI_caseInfo .button-container {
    margin-top: -30px;
}

.UI_caseInfo .desc {
    max-width: 170px;
}




.UI_caseInfo .left-container .icons {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
}

.UI_caseInfo .left-container .icons .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.UI_caseInfo .left-container .icons .icon p {
    text-align: center;
}

.UI_caseInfo .left-container .icons .icon img {
    flex-shrink: 0;
    object-fit: cover;
    margin-bottom: 7px;
}



@media screen and (max-width: 1200px) {
    .UI_caseInfo {
        padding: 17px 15px 40px 15px;
        display: flex;
        flex-direction: column;
    }

    .UI_caseInfo .container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }

    .UI_caseInfo .container .info {
        display: flex;
        flex-direction: column;
        gap: 18px;
        width: 90%;
        max-width: 270px;
        margin: auto;
        flex-shrink: 0;
    }

    .UI_caseInfo .container .info .f-20 {
        width: auto;
        font-size: 14px;
    }

    .UI_caseInfo .left-container .text {
        width: 95%;
        margin: auto;
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-top: 35px;
        align-items: center;
        display: none;
    }

    .UI_caseInfo .left-container .text .challenge,
    .UI_caseInfo .left-container .text .solution {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 45%;
        font-size: 14px;
    }

    .UI_caseInfo .left-container {
        width: 100%;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .UI_caseInfo .button-container {
        margin-top: 30px;
    }

    .UI_caseInfo .number-wrapper {
        display: flex;
        align-items: center;
        gap: 11px;
        margin-left: 0;
        margin-bottom: 20px;
    }

    .UI_caseInfo .number-wrapper .number {
        border: 1px solid #545454;
        width: 19px;
        height: 19px;
        line-height: 18px;
        text-align: center;
        border-radius: 50%;
        box-sizing: border-box;
        padding-bottom: 1px;
        font-size: 14px;
    }
}