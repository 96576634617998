.fullWidthVideo {
    width: 100vw;
    height: auto;
}

@media screen and (max-width: 1200px) {
    .fullWidthVideo {
        width: 100%;
        height: auto;
        display: none;
    }
}