.header {
  height: 91px;
  display: flex;
  padding: 0 46px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  box-sizing: border-box;
  transition: transform 0.3s ease;
  z-index: 5;
  overflow: visible;
}

.header.hidden {
  transform: translateY(-100%);
}

.header.visible {
  transform: translateY(0);
}

.header img {
  width: 129.19px;
  height: 31.16px;
}

.header>ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr) 20px;
  align-items: center;
  width: 490px;
  height: 41px;
  overflow: hidden;
  background: linear-gradient(180deg, #111111 8.5%, rgba(38, 38, 38, 0.64) 100%);
  border-radius: 71px;
  border: 1px solid #262626;
  backdrop-filter: blur(4px);
  padding: 0 22px 0 16px;
  position: relative;
  overflow: visible;
}

.header .lang {
  top: 4px;
  right: 10px;
  position: absolute;
  width: 33px;
  height: 108px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
  background: #35353562;
  padding: 0px 3px;
  border-radius: 20px;
  background: linear-gradient(180deg, #111111 8.5%, rgba(38, 38, 38, 0.64) 100%);
  border: 1px solid #262626;
  box-sizing: border-box;
  transition: all 0.1s;
  user-select: none;
  overflow: hidden;
}

.header .lang.hidden {
  height: 32px;
  top: 4px;
  padding: 0 3px;
}

.header .lang li {
  width: 100%;
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.header .lang.hidden li::before {
  display: none;
}

.header .lang li::before {
  content: '';
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 1px;
  background-color: #fff;
  transition: all 0.2s;
}

.header .lang li:hover::before {
  width: 70%;
}

.header>ul li {
  text-align: center;
}

.header>ul a {
  font-weight: 450;
  position: relative;
  cursor: pointer;
}

.header>ul a::before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: all 0.2s;
}

.header>ul a.active::before {
  width: 100%;
}

.header>ul a:hover::before {
  width: 100%;
}

.header .mobileHeader {
  display: none;
}

.mobileNav {
  display: none;
}

.languages {
  display: none;
}

@media screen and (max-width: 1200px) {

  .header>ul,
  .header>div,
  .header>img {
    display: none;
  }

  .header.hidden {
    transform: translateY(-200%);
  }

  .header {
    left: 5%;
  }


  .header {
    border-radius: 20px;
    background: linear-gradient(180deg, #111111 8.5%, rgba(38, 38, 38, 0.64) 100%);
    border: 1px solid #262626;
    backdrop-filter: blur(4px);
    height: 43px;
    width: 90%;
    padding: 0;
    box-sizing: border-box;
    margin: auto;
    margin-top: 22px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s;
    overflow: hidden;
    gap: 5%;
    flex-direction: column;
  }

  .header .mobileHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 41px;
  }

  .header img {
    width: 83px;
  }

  .header .burger {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .header .burger div {
    width: 26px;
    height: 2px;
    background-color: #fff;
    border-radius: 10px;
  }

  .header.open {
    align-items: flex-start;
    min-height: calc(100dvh - 43px);
    /* height: fit-content; */
    max-height: 100%;
    padding-bottom: 40px;
  }

  .tabs {
    flex-direction: column;
    gap: 0;
    font-size: 40px;
    font-weight: 450;
    display: flex;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    height: 100%;
    text-align: center;

  }

  .mobileNav {
    display: none;
    width: 100%;
    height: 100%;
  }

  .header.open .mobileNav {
    display: block;
  }

  .languages {
    display: none;
  }

  .header.open .languages {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: -15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: -15px;
    background: linear-gradient(180deg, rgba(38, 38, 38, 0.64) 8.5%, #111111 100%);
    padding: 15px 20px;
    border-radius: 15px;
  }
}