.footer {
    padding: 0 46px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 20px;
}

.footer .top {
    display: flex;
    justify-content: space-between;
}

.footer .icons {
    display: flex;
    gap: 15px;
    align-items: center;
}

.footer .bottom {
    display: flex;
    justify-content: space-between;
}

.footer .bottom span {
    color: #666666;
}

.footer .bottom ul {
    display: flex;
    gap: 20px;
}

.footer .bottom ul p {
    text-decoration: underline;
    cursor: pointer;
}

.notification {
    position: fixed;
    max-width: 553px;
    width: 100%;
    max-height: 90vh;
    width: 100%;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    z-index: -20;
    background: linear-gradient(159.4deg, #000000 5.97%, #1F1F1F 86.98%);
    border: 2px solid #585858;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    padding: 55px 30px 35px 30px;
    transition: all 0.5s;
    overflow-y: auto;
    opacity: 0;
}

.notification p {
    line-height: 160%;
}

.notification.visible {
    opacity: 1;
    z-index: 10;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    transition: all 0.3s;
}

.overlay.visible {
    width: 100vw;
    height: 100vh;
}



@media screen and (max-width: 1200px) {
    .footer {
        width: 85%;
        padding: 0 0 20px 0;
    }
    .logo {
        display: none;
    }

    .icons > img {
        width: 23px;
        height: 23px;
    }

    .footer .bottom {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 10px;
    }

    .notification {
        max-width: 90vw;
    }
}